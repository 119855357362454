@import 'styles/utils';

.skipLinksWrapper {
  position: fixed;
  top: 5px;
  left: -10000px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $grey-50;
  z-index: 1303;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skipLinksList {
  list-style: none;
  margin: 0;
  color: $dark-Grey;
}

.skipLink {
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  &:hover {
    @include color-opacity;
  }
}

.skipLinksWrapper:focus-within {
  left: 5px;
  width: auto;
  height: auto;
}

.seperator {
  margin: 0 16px 16px 16px;
  height: 1px;
  background-color: $grey-10;
}

.quickNavHint {
  color: $dark-Grey;
  margin: 0 16px 16px 16px;
  max-width: 270px;
  display: inline-block;
}