@import 'styles/utils';

.container {
    display: none;
    align-items: center;
    position:fixed;
    --notification-bubble-padding: 20px;
    height: calc(var(--button-width) - (2 * var(--notification-bubble-padding)));
    top: calc(40% - (10px + (var(--button-width) / 2)));
    right: 50px;
    z-index: 10;
    max-width: 400px;
    // max-height: fit-content;
    margin: 1rem;
    transform-origin: top right;
    z-index: 150;
    &.top {
      top: calc(40% - (10px + (var(--button-width) / 2)));
    }
    &.bottom {
      top: calc(40% + (10px + (var(--button-width) / 2)));
    }
    @include breakpoint('md') {
      display: flex;
    }
  }
  
  .avatar {
    margin-right: 20px;
    img {
        border-radius: 25px;

    }
  }
  
  .bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 4px;
    padding: var(--notification-bubble-padding) var(--notification-bubble-padding);
    box-shadow: 0px 0 4px 0px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index:5;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    /* border-style: solid; */
    /* border-width: 8px 8px 8px 0; */
    z-index: 10;
    box-shadow:0px 0 4px 0px rgba(0, 0, 0, 0.3);

    /* border-color: transparent #f5f5f5 transparent transparent; */
    background: white;
    z-index:1;
  }
  .arrowRight {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 10px solid white; /* Change the color as needed */
    filter: drop-shadow(1.5px 0px 1.1px rgba(0, 0, 0, 0.1)); 
    position:absolute;
    right: -10px;
    top:calc(50% - 5px);
  }
  .notificationText {
    width: 200px;
  }
  .closeIcon {
    position:absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    background-color: inherit;
    padding: 0;
    border: 0;
  }
  .icon {
    height: 18px;
    width: 18px;
  }
  .hidden {
    display: none;
  }